.App {
  width: 100%;
}


.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.navigationhead {
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.footer {
  background-color: #212529;
  position: fixed;
  width: 100%;
  bottom: 0;
  color: white;
  font-size: 16px;
 }

.box-100 {
  transition:all 0.3s ease;
  cursor:pointer;
  box-shadow: 0px 0px 2px 6px rgba(4, 182, 105, 0.03);
  width: 150px;
  height: 150px;
  border-radius: 150px;    
  background-color: #112866;    
}

$theme-colors: (
  "primary": #112866
);


.knight-link {
  color: #112866; text-decoration: none;
}

.knight-link:a {
  color: #112866; text-decoration: none;
}

.big-icon{
font-size: 48px;    
color: #dddddd;
//color: #BF9429;
}
.cc {
    transition: all 0.3s ease;
    cursor: pointer;
}
.cc:hover {
    box-shadow: 0px 0px 32px 2px rgba(216, 4, 4, 0.06);
}

.raffle-image {
  transform:rotate(346deg);
  width: 95%;
}

.text-navy {   
  color: "navy";
}

.square-container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.margin-top-2 {
  margin-top:2em
}

@import 'react-big-calendar/lib/sass/styles';